import cx from "classnames";
import styles from "./styles.module.scss";
import { Caption, Cell, Text } from "@telegram-apps/telegram-ui";
import { formatAmount } from "../../utils/formatNumbers";
import React from "react";

const TransactionCell = ({
  transaction,
  isLast,
  refCallback,
  getDate,
  getIsSentTransaction,
}) => {
  const isSent = getIsSentTransaction(transaction);
  const amountClass = cx(styles.amount, { [styles.received]: !isSent });

  return (
    <Cell
      ref={isLast ? refCallback : null}
      description={getDate(transaction.inserted_at)}
      multiline
      className={styles.cell}
      after={
        <div className={styles.cellAfter}>
          <Text className={amountClass}>
            {`${!isSent ? "+" : ""}${formatAmount(transaction.amount)} ${transaction.currency}`}
          </Text>
          <Caption className={amountClass}>
            {isSent ? "Sent" : "Received"}
          </Caption>
        </div>
      }
      key={transaction.id}
    >
      {transaction.description}
    </Cell>
  );
};

export default TransactionCell;
