import React from "react";
import styles from "./styles.module.scss";
import { Caption, Cell, Navigation, Image } from "@telegram-apps/telegram-ui";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import { useTasksPageHook } from "../../hooks/Pages/useTasksHook";
import { formatAmount } from "../../utils/formatNumbers";
import { AiOutlineCheck } from "react-icons/ai";
import PageTitle from "../../components/PageTitle/PageTitle";

const Tasks = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    lastTaskElementRef,
    isFirstRender,
    handleTaskClick,
    tasksData,
  } = useTasksPageHook();

  if (isLoading && isFirstRender) {
    return <LoaderComponent />;
  }

  return (
    <div className={`${styles.tasksWrapper} ${styles.fadeIn}`}>
      <PageTitle title={"earn_more_coins"} />
      <div className={styles.tasksList}>
        {tasksData.map((task, index) => (
          <Cell
            key={index}
            className={styles.cell}
            after={
              task.status === "finished" ? (
                <AiOutlineCheck color={"green"} />
              ) : (
                <Navigation />
              )
            }
            before={<Image size={48} src={task.image_url} />}
            subhead={
              <Caption level="1" weight="1">
                {`+${formatAmount(task.money_reward.amount)} ${task.money_reward.currency}`}
              </Caption>
            }
            onClick={() => handleTaskClick(task)}
            ref={index === tasksData.length - 1 ? lastTaskElementRef : null}
          >
            <span className={styles.customFont}>{t(task.title)}</span>
          </Cell>
        ))}
      </div>
    </div>
  );
};

export default Tasks;
