import React from "react";
import styles from "./styles.module.scss";
import { Image, Text } from "@telegram-apps/telegram-ui";
import { useMainPageHook } from "../../hooks/Pages/useMainPageHook";
import { formatAmount, formatNumber } from "../../utils/formatNumbers";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import { PreloadImages } from "../../utils/globals";
import cx from "classnames";
import FancyImageComponent from "../../components/ForMainPage/FancyImageComponent";
import MainPageButton from "../../components/ForMainPage/MainPageButton";

const MainPage = () => {
  const {
    buttonData,
    userAmount,
    isLoading,
    userRating,
    handleNavigateToRating,
    t,
  } = useMainPageHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={styles.mainPageWrapper}>
      <div className={cx(styles.fancyImagesRow, styles.fadeIn)}>
        <FancyImageComponent altText="fancy image 1">
          <div className={styles.fancyContent}>
            <Image
              size={25}
              className={styles.coinImage}
              src={PreloadImages.COIN_ICON}
            />
            <Text weight="1">{formatNumber(userAmount)}</Text>
          </div>
        </FancyImageComponent>
        <FancyImageComponent
          className={styles.activeFancy}
          altText="fancy image 2"
        >
          <div className={styles.fancyContent} onClick={handleNavigateToRating}>
            <Text weight="1">
              {t("rank")}
              {` ${userRating < 100000 ? formatAmount(userRating) : formatNumber(userRating) || "-"}`}
            </Text>
          </div>
        </FancyImageComponent>
      </div>
      <div className={cx(styles.buttonsColumn, styles.fadeIn)}>
        {buttonData.map((button, index) => (
          <MainPageButton button={button} key={index} t={t} />
        ))}
      </div>
    </div>
  );
};

export default MainPage;
