import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "./useTelegramHook";

const useTelegramBackButton = (
  redirectPath,
  backState = {},
  isNoHide = false,
) => {
  const { tg } = useTelegram();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const navigate = useNavigate();

  useEffect(() => {
    if (tg) {
      const handleBackButtonClick = () => {
        if (hapticEnabled) tg.HapticFeedback.selectionChanged();
        setTimeout(() => {
          navigate(redirectPath, backState);
        }, 100);
      };

      tg.BackButton.show();
      tg.BackButton.onClick(handleBackButtonClick);

      return () => {
        tg.BackButton.offClick(handleBackButtonClick);
        if (!isNoHide) tg.BackButton.hide();
      };
    }
  }, [tg]);
};

export default useTelegramBackButton;
