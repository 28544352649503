import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTelegram } from "../Layout/useTelegramHook";
import { getDetailHeroes, getHero } from "../../services/heroService";
import { fetchUserData } from "../../services/registrationService";
import { useSwipeable } from "react-swipeable";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import paths from "../../pages/paths";

export const useHeroHook = (setIsLoaderEnd, hero) => {
  const { tg } = useTelegram();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const dispatch = useDispatch();
  const location = useLocation();
  const initialCharacter = hero ? hero : location.state?.character;
  const navigate = useNavigate();

  const isFromRoulette = !!hero;

  const [characterId, setCharacterId] = useState(initialCharacter.id);
  const [heroData, setHeroData] = useState(null);
  const [nextHeroId, setNextHeroId] = useState(null);
  const [prevHeroId, setPrevHeroId] = useState(null);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [loadedImages, setLoadedImages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState({
    mainCard: false,
    texture: false,
    hero: false,
  });

  const [searchParams, _] = useSearchParams();

  const queryParams = {
    page: String(searchParams.get("page") || 1),
    order_by: String(searchParams.get("order_by") || "total_stats_desc"),
    class_ids: String(searchParams.get("class_ids") || ""),
    hero_ids: String(searchParams.get("hero_ids") || ""),
    rarity_level_ids: String(searchParams.get("rarity_level_ids") || ""),
  };

  const queryString = new URLSearchParams(queryParams).toString();

  useTelegramBackButton(`${paths.heroes}?${queryString}`);

  useEffect(() => {
    const fetchHeroDetails = async (id) => {
      try {
        const data = await getDetailHeroes(id, queryParams);
        setNextHeroId(data.next_id);
        setPrevHeroId(data.previous_id);
        const hero = await getHero(id);
        setHeroData(hero);
      } catch (error) {
        console.error("Failed to fetch hero details", error);
      } finally {
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    fetchUserData(dispatch);
    fetchHeroDetails(characterId);
  }, [characterId, dispatch]);

  const totalImages = 3;
  const allLoaded = loadedImages === totalImages;

  const handleClick = (e) => {
    e.preventDefault();
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
  };

  const handleAllImagesLoaded = () => {
    setAllImagesLoaded(true);
    setIsLoaderEnd?.(true);
  };

  useEffect(() => {
    if (allLoaded) {
      handleAllImagesLoaded();
    }
  }, [allLoaded]);

  const handleImageLoad = (imageName) => {
    setLoadedImages((prev) => prev + 1);
    setIsImageLoaded((prev) => ({
      ...prev,
      [imageName]: true,
    }));
  };

  const handleImageError = (imageName) => {
    setLoadedImages((prev) => prev + 1);
    setIsImageLoaded((prev) => ({
      ...prev,
      [imageName]: false,
    }));
  };

  const goToNextHero = () => {
    if (isFromRoulette) return;
    if (nextHeroId) {
      setLoadedImages(0);
      setAllImagesLoaded(false);
      setCharacterId(nextHeroId);
    }
  };

  const goToPrevHero = () => {
    if (isFromRoulette) return;
    if (prevHeroId) {
      setLoadedImages(0);
      setAllImagesLoaded(false);
      setCharacterId(prevHeroId);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: goToNextHero,
    onSwipedRight: goToPrevHero,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleOpenDetailPage = () => {
    if (isFromRoulette) return;
    navigate(`${paths.heroDetail}?${queryString}`, {
      state: { hero: heroData },
    });
  };

  return {
    heroData,
    handleClick,
    allImagesLoaded,
    allLoaded,
    isImageLoaded,
    handleImageLoad,
    handleImageError,
    isLoading,
    swipeHandlers,
    handleOpenDetailPage,
    isFromRoulette,
    goToNextHero,
    goToPrevHero,
    nextHeroId,
    prevHeroId,
  };
};
