import { useState } from "react";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../store/slices/loaderSlice";
import { setIsGame } from "../../store/slices/telegramSlice";

export const useGuideHook = () => {
  const [activeStep, setActiveStep] = useState(1);

  const dispatch = useDispatch();

  const handleNextStep = () => {
    if (activeStep < 3) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleStartGame = () => {
    dispatch(setIsGame(true));
    dispatch(setGlobalLoading(true));
  };

  const handlePreviousStep = () => {
    if (activeStep > 1) {
      setActiveStep(activeStep - 1);
    }
  };

  return {
    activeStep,
    handleNextStep,
    handlePreviousStep,
    handleStartGame,
  };
};
