import { apiUrl } from "../utils/globals";
import { axiosListInstance } from "./axiosService";

const getUserReferrals = (page, pageSize) => {
  return axiosListInstance.get(`${apiUrl}me/referrals`, {
    params: {
      page,
      page_size: pageSize,
    },
  });
};

export { getUserReferrals };
