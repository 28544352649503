import React from "react";
import styles from "./styles.module.scss";
import { LargeTitle } from "@telegram-apps/telegram-ui";
import { useTranslation } from "react-i18next";
import cx from "classnames";

const PageTitle = ({ title, children, isNoMargin }) => {
  const { t } = useTranslation();
  return (
    <LargeTitle
      className={cx(styles.title, { [styles.noMargin]: isNoMargin })}
      weight="1"
    >
      {title ? t(title) : children}
    </LargeTitle>
  );
};

export default PageTitle;
