import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTelegram } from "./useTelegramHook";
import paths from "../../pages/paths";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { setIsPlaying } from "../../store/slices/telegramSlice";

export const useHeaderHook = () => {
  const { pathname } = useLocation();
  const { tg } = useTelegram();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const username = useSelector((state) => state.telegram.username);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const telegramData = useSelector((state) => state.telegram.telegramData);
  const isPlaying = useSelector((state) => state.telegram.isPlaying);

  const colorScheme = telegramData?.colorScheme;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);

  const handleGoToProfile = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.profile, { state: { from: pathname } });
  };

  const handleGoToReferral = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.referral, { state: { from: pathname } });
  };

  const { t } = useTranslation();

  const handleToggleMusic = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (!isPlaying) {
      setAttemptCount((prevCount) => prevCount + 1);
    }
    dispatch(setIsPlaying(!isPlaying));
    handleShowSnackbar();
  };

  const handleShowSnackbar = () => {
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const isThirdAttempt = attemptCount > 0 && attemptCount % 3 === 0;

  const getDescriptionMessage = () => {
    if (isThirdAttempt) {
      return t("If the music doesn't start, please reload the page.");
    } else if (attemptCount === 1 && isPlaying) {
      return t("Please turn on the sound on your phone.");
    }
    return "";
  };

  return {
    username,
    handleGoToProfile,
    handleGoToReferral,
    colorScheme,
    snackbarOpen,
    handleToggleMusic,
    handleCloseSnackbar,
    getDescriptionMessage,
    isThirdAttempt,
    isPlaying,
  };
};
