import { axiosInstance } from "./axiosService";

const getMarketItems = () => {
  return axiosInstance.get("/market_items");
};
const getMarketItem = (id) => {
  return axiosInstance.get(`/market_items/${id}`);
};
const buyMarketItem = (id) => {
  return axiosInstance.post(`/market_items/${id}/buy_item`);
};
const getMeMarketItems = (id) => {
  return axiosInstance.get("/me/market_items", {
    params: {
      is_used: false,
      market_item_id: id,
    },
  });
};

const getMeMarketItem = (id) => {
  return axiosInstance.get(`/me/market_items/${id}`);
};
const openMarketItems = (id) => {
  return axiosInstance.post(`/me/market_items/${id}`);
};

export {
  getMarketItems,
  getMarketItem,
  buyMarketItem,
  getMeMarketItems,
  openMarketItems,
  getMeMarketItem,
};
