import React from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { Icon24ChevronLeft } from "@telegram-apps/telegram-ui/dist/icons/24/chevron_left";
import { Icon24ChevronRight } from "@telegram-apps/telegram-ui/dist/icons/24/chevron_right";

const PaginationControls = ({ page, hasMore, onNext, onPrev }) => (
  <>
    <button
      disabled={page === 1}
      onClick={onPrev}
      className={cx(styles.leftButton, {
        [styles.disabledButton]: page === 1,
      })}
    >
      <Icon24ChevronLeft />
    </button>
    <button
      disabled={!hasMore}
      onClick={onNext}
      className={cx(styles.rightButton, {
        [styles.disabledButton]: !hasMore,
      })}
    >
      <Icon24ChevronRight />
    </button>
  </>
);

export default PaginationControls;
