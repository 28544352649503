import React from "react";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import { Cell, Image, Info, Text } from "@telegram-apps/telegram-ui";
import { PreloadImages } from "../../utils/globals";
import styles from "./styles.module.scss";
import MarketSnackbar from "../Market/MarketSnackbar";
import { formatAmount } from "../../utils/formatNumbers";
import { BsArrowRight } from "react-icons/bs";
import { useMarketItemHook } from "../../hooks/Pages/useMarketItemHook";
import InfoModal from "../../components/InfoModal/InfoModal";
import { StarIcon } from "../../assets/icons";
import SuccessModal from "../Market/SuccessModal";
import cx from "classnames";

const MarketItem = () => {
  const {
    handleCloseModal,
    handleOpenModal,
    handleBuyMarketItem,
    isLoading,
    isModalOpen,
    snackbar,
    t,
    marketItem,
    setSnackbar,
    handleOpenRoulette,
    isSuccessModalOpen,
    setIsSuccessModalOpen,
  } = useMarketItemHook();

  if (isLoading) return <LoaderComponent />;

  return (
    <>
      <div className={cx(styles.marketItemWrapper, styles.fadeIn)}>
        <img alt="loot" className={styles.image} src={marketItem.image_url} />
        <div className={styles.marketItemContent}>
          <Info
            className={styles.info}
            subtitle={
              marketItem.sale
                ? `${marketItem.sale.discount_percentage}% ${t("SALE")}`
                : ""
            }
            type="text"
          >
            {marketItem.title}
          </Info>
          <TelegramButton
            onClick={handleBuyMarketItem}
            className={styles.button}
          >
            <div className={styles.buttonColumn}>
              <Text>
                {marketItem.sale ? (
                  <>
                    <span className={styles.strikethrough}>
                      {formatAmount(marketItem?.price.amount)}
                    </span>
                    <BsArrowRight className={styles.arrowIcon} />
                    {formatAmount(marketItem?.sale?.final_price)}
                  </>
                ) : (
                  `${formatAmount(marketItem?.price.amount)}`
                )}
              </Text>
              {marketItem.price.currency === "STAR" ? (
                <StarIcon size={20} />
              ) : (
                <Image
                  size={24}
                  className={styles.coinImage}
                  src={PreloadImages.COIN_ICON}
                />
              )}
            </div>
          </TelegramButton>
          <Cell className={styles.topCell} multiline subhead={t("Description")}>
            <Text>{marketItem.description}</Text>
          </Cell>
          <Cell
            className={styles.bottomCell}
            multiline
            after={
              <TelegramButton onClick={handleOpenModal}>
                {t("More")}
              </TelegramButton>
            }
            subhead={t("Content")}
          >
            <Text>{t("Click more or check out on pathgame.app")}</Text>
          </Cell>
        </div>
      </div>
      {snackbar.open && (
        <MarketSnackbar
          setSnackbar={setSnackbar}
          snackbar={snackbar}
          handleOpenRoulette={handleOpenRoulette}
        />
      )}

      {isModalOpen && <InfoModal handleCloseModal={handleCloseModal} />}
      <SuccessModal
        activeItemImage={marketItem?.image_url}
        handleOpenRoulette={handleOpenRoulette}
        isSuccessModalOpen={isSuccessModalOpen}
        setIsSuccessModalOpen={setIsSuccessModalOpen}
      />
    </>
  );
};

export default MarketItem;
