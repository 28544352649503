import React from "react";
import styles from "./styles.module.scss";
import { Badge, Headline } from "@telegram-apps/telegram-ui";

const MainPageButton = ({ button, t }) => {
  return (
    <button
      className={styles.button}
      onClick={button.onClick}
      style={{ backgroundImage: `url(${button.backgroundImage})` }}
    >
      <Headline className={styles.title} weight="1">
        {button.title}
      </Headline>
      {button.title === t("play") && (
        <Badge className={styles.badge} mode="primary" type="number">
          {t("why_click_here")}
        </Badge>
      )}
    </button>
  );
};

export default MainPageButton;
