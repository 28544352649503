import { configureStore } from "@reduxjs/toolkit";
import telegramReducer from "./slices/telegramSlice";
import loaderReducer from "./slices/loaderSlice";

export const store = configureStore({
  reducer: {
    telegram: telegramReducer,
    loader: loaderReducer,
  },
});
