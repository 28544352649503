import React from "react";
import { List } from "@telegram-apps/telegram-ui";
import styles from "./styles.module.scss";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import { useTransactionsHook } from "../../hooks/Pages/useTransactionsHook";
import PageTitle from "../../components/PageTitle/PageTitle";
import TransactionCell from "./TransactionsCell";
import cx from "classnames";

const Transactions = () => {
  const {
    transactions,
    isDataLoading,
    page,
    getIsSentTransaction,
    lastTransactionElementRef,
    isLoading,
    getDate,
  } = useTransactionsHook();

  if (isLoading || (isDataLoading && page === 1)) {
    return <LoaderComponent />;
  }

  return (
    <div className={cx(styles.transactionsWrapper, styles.fadeIn)}>
      <PageTitle title="Transaction history" />
      {!transactions?.length && !isDataLoading && <div>Беда...</div>}
      {!!transactions?.length && (
        <List className={styles.list}>
          {transactions.map((transaction, index) => (
            <TransactionCell
              key={transaction.id}
              transaction={transaction}
              isLast={index === transactions.length - 1}
              refCallback={lastTransactionElementRef}
              getDate={getDate}
              getIsSentTransaction={getIsSentTransaction}
            />
          ))}
        </List>
      )}
    </div>
  );
};

export default Transactions;
