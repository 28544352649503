import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalLoading } from "../../store/slices/loaderSlice";

const useLoaderHook = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader.isLoading);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => {
        dispatch(setGlobalLoading(false));
      }, 300);
    }

    return () => clearTimeout(timer);
  }, [isLoading, dispatch]);
};

export default useLoaderHook;
