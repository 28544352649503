import { apiUrl } from "../utils/globals";
import { axiosListInstance } from "./axiosService";

export const getTransactions = (id, page, pageSize) => {
  return axiosListInstance.get(`${apiUrl}me/wallets/${id}/transactions`, {
    params: {
      page: page,
      page_size: pageSize,
    },
  });
};
