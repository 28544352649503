export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#20232a" : "#333842", // Custom background for focused state
    borderColor: state.isFocused ? "#61dafb" : "#4b4f58", // Custom border color for focused state
    boxShadow: state.isFocused ? "0 0 0 1px #61dafb" : "none", // Custom focus border
    "&:hover": {
      borderColor: "#61dafb", // Change border color on hover
    },
    borderRadius: "16px",
    minHeight: "42px",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#333842",
    position: "initial",
    zIndex: 9999,
    borderRadius: "16px",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    borderRadius: "16px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#61dafb"
      : state.isFocused
        ? "#4b4f58"
        : "#333842", // Custom color for selected and hovered options
    color: state.isSelected ? "#20232a" : "#ffffff", // Custom color for selected text
    "&:hover": {
      backgroundColor: "#4b4f58", // Custom hover color
    },
    borderRadius: "16px",
  }),
  // singleValue: (provided) => ({
  //   ...provided,
  //   color: "#ffffff", // Color for selected option's label
  //   // borderRadius: "16px",
  // }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#4b4f58", // Background color for selected multi-value option
    borderRadius: "16px",
    padding: "0 4px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#ffffff", // Text color for selected multi-value option
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#ffffff", // Custom color for remove icon
    ":hover": {
      backgroundColor: "#61dafb",
      color: "#20232a",
      borderRadius: "16px",
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};
