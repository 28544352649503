import { useDispatch } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import paths from "../../pages/paths";
import { useNavigate } from "react-router-dom";
import useAndroidScrollFix from "../Layout/useAndroidScrollFix";
import { fetchUserData } from "../../services/registrationService";
import { getGiveaways } from "../../services/giveawaysService";
import useLoaderHook from "../Layout/useLoaderHook";

export const useGiveawaysHook = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useTelegramBackButton(-1, {}, true);
  const [giveawaysData, setGiveawaysData] = useState([]);
  const [activeStatus, setActiveStatus] = useState("active");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const navigate = useNavigate();
  useAndroidScrollFix();
  useLoaderHook();

  const observer = useRef();

  const lastGiveawayElementRef = useCallback(
    (node) => {
      if (isDataLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isDataLoading, hasMore],
  );

  useEffect(() => {
    fetchUserData(dispatch).finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setIsDataLoading(true);
    getGiveaways(page, 10, activeStatus)
      .then((response) => {
        const data = response.data.data || [];
        const paginationInfo = response.data.pagination_info || {};

        setGiveawaysData((prevData) => [...prevData, ...data]);
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
      })
      .finally(() => setIsDataLoading(false));
  }, [activeStatus, page]);

  const handleGoToGiveaway = (id) => {
    navigate(paths.giveaway, { state: id });
  };

  const calculateProgress = (startedAt, endedAt) => {
    const now = new Date();
    const start = new Date(startedAt);
    const end = new Date(endedAt);

    const totalDuration = end - start;

    if (totalDuration === 0) {
      return 0;
    }

    const remainingTime = end - now;
    return Math.max((remainingTime / totalDuration) * 100, 0);
  };

  const handleChangeStatus = (status) => {
    if (activeStatus === status) return;
    setPage(1);
    setGiveawaysData([]);
    setActiveStatus(status);
  };
  return {
    isLoading,
    handleChangeStatus,
    activeStatus,
    isDataLoading,
    page,
    giveawaysData,
    lastGiveawayElementRef,
    handleGoToGiveaway,
    calculateProgress,
  };
};
