import styles from "./styles.module.scss";
import React from "react";
import { PreloadImages } from "../../utils/globals";
import cx from "classnames";

const FancyImageComponent = ({ altText, children, className, onClick }) => (
  <div
    onClick={onClick}
    className={cx(styles.fancyImage, className)}
    style={{ backgroundImage: `url(${PreloadImages.FANCY_BUTTON})` }}
    aria-label={altText}
  >
    {children}
  </div>
);

export default FancyImageComponent;
