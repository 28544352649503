import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DefaultLayout from "../components/common/DefaultLayout/DefaultLayout";
import routesConfig from "../utils/routesConfig";

const Game = () => {
  return (
    <BrowserRouter>
      <DefaultLayout>
        <Routes>
          {routesConfig.map(({ path, component: Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </DefaultLayout>
    </BrowserRouter>
  );
};

export default Game;
