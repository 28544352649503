import { AsyncPreloadImages } from "./asyncPreload";

export const getCharImagesByRarity = (attribute) => {
  const attributeKey = `${attribute.toUpperCase()}_ICON`;
  return AsyncPreloadImages[attributeKey] || "";
};

export const getCardImagesByRarity = (rarityLevel) => {
  if (!rarityLevel) return "";
  const rarity = rarityLevel?.toUpperCase();
  return {
    BACK: {
      MAIN: AsyncPreloadImages[`${rarity}_BACK_MAIN`],
      PREVIEW: AsyncPreloadImages[`${rarity}_BACK_PREVIEW`],
    },
    FRONT: {
      MAIN: AsyncPreloadImages[`${rarity}_FRONT_MAIN`],
      PREVIEW: AsyncPreloadImages[`${rarity}_FRONT_PREVIEW`],
      TEXTURE: AsyncPreloadImages[`${rarity}_FRONT_TEXTURE`],
      TEXTURE_PREVIEW: AsyncPreloadImages[`${rarity}_FRONT_TEXTURE_PREVIEW`],
    },
  };
};

export const getClassIcon = (classType) => {
  const classKey = `${classType.toUpperCase()}_ICON`;
  return AsyncPreloadImages[classKey] || "";
};

export const getLeaderImage = (index) => {
  if (index === 1) return AsyncPreloadImages.LEADERBOARD_FIRST;
  if (index === 2) return AsyncPreloadImages.LEADERBOARD_SECOND;
  if (index === 3) return AsyncPreloadImages.LEADERBOARD_THIRD;
  if (index > 3 && index <= 51) return AsyncPreloadImages.LEADERBOARD_STONE;
  if (index > 51) return AsyncPreloadImages.LEADERBOARD_WOOD;
  return null;
};

export const getColorByClassName = (classname) => {
  const colors = {
    common: "saddlebrown",
    rare: "gray",
    elite: "orange",
    legendary: "cornflowerblue",
    divine: "rgb(50, 229, 94)",
  };

  return colors[classname] || "black";
};
