import React from "react";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import styles from "./common.module.scss";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { Caption, Image, Modal, Text } from "@telegram-apps/telegram-ui";
import { formatAmount } from "../../utils/formatNumbers";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";

const MarketModal = ({
  isModalOpen,
  setIsModalOpen,
  activeModalItem,
  handleBuyMarketItem,
  t,
}) => {
  return (
    <Modal
      open={isModalOpen}
      onOpenChange={(open) => {
        if (!open) setIsModalOpen(false);
      }}
      header={
        <ModalHeader
          className={styles.modalHeader}
          after={
            <ModalClose>
              <Icon28Close
                style={{ color: "var(--tgui--plain_foreground)" }}
                onClick={() => setIsModalOpen(false)}
              />
            </ModalClose>
          }
        />
      }
    >
      <div className={styles.modalContent}>
        <Image
          className={styles.modalImage}
          size={96}
          src={activeModalItem?.image_url}
        />
        <Text>
          <strong>{t("Confirm Your Purchase")}</strong>
        </Text>
        <Caption>
          {t("Do you want to buy")} <strong>{activeModalItem?.title}</strong>
          &nbsp;{t("for")}&nbsp;
          <strong>
            {activeModalItem?.sale
              ? activeModalItem?.sale?.final_price
              : formatAmount(activeModalItem?.price.amount)}
          </strong>
          &nbsp;
          <strong>{activeModalItem?.price?.currency}</strong>?
        </Caption>
        <TelegramButton
          className={styles.confirmButton}
          onClick={() => handleBuyMarketItem(activeModalItem?.id)}
        >
          {t("Confirm and Pay")}
        </TelegramButton>
      </div>
    </Modal>
  );
};

export default MarketModal;
