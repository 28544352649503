import React from "react";
import i18n from "i18next";
import styles from "./styles.module.scss";
import { Select } from "@telegram-apps/telegram-ui";
import { useSelector } from "react-redux";
import { useTelegram } from "../../../hooks/Layout/useTelegramHook";
import { updateUserLanguage } from "../../../services/walletService";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = ({
  activeLanguage,
  setActiveLanguage,
  setIsLoading,
}) => {
  const { t } = useTranslation();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();

  const handleChangeLanguage = async (e) => {
    const value = e.target.value;
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();

    const languageMap = {
      [t("Russian")]: "ru",
      [t("English")]: "en",
      [t("Ukrainian")]: "uk",
      [t("Italian")]: "it",
      [t("German")]: "de",
    };

    const languageCode = languageMap[value] || "en";

    setIsLoading(true);
    try {
      await updateUserLanguage(languageCode);
      await i18n.changeLanguage(languageCode);
      setActiveLanguage(languageCode);
    } catch (error) {
      console.error("Failed to update language:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getLanguageText = (language) => {
    switch (language) {
      case "ru":
        return t("Russian");
      case "uk":
        return t("Ukrainian");
      case "it":
        return t("Italian");
      case "de":
        return t("German");
      default:
        return t("English");
    }
  };

  const languageText = getLanguageText(activeLanguage);

  return (
    <div className={styles.languageSwitcher}>
      <Select
        header={t("Language Select")}
        value={languageText}
        placeholder={t("Select a language")}
        onChange={handleChangeLanguage}
        className={styles.select}
        onFocus={() => {
          if (hapticEnabled) tg.HapticFeedback.selectionChanged();
        }}
      >
        <option>{t("English")}</option>
        <option>{t("Russian")}</option>
        <option>{t("Ukrainian")}</option>
        <option>{t("Italian")}</option>
        <option>{t("German")}</option>
      </Select>
    </div>
  );
};

export default LanguageSwitcher;
