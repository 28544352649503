import { useTelegram } from "./useTelegramHook";
import { useLocation, useNavigate } from "react-router-dom";
import usePreloadImages from "./usePreloadImages";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import paths from "../../pages/paths";

export const useDefaultLayoutHook = () => {
  const { tg } = useTelegram();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  usePreloadImages(AsyncPreloadImages);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const isInitialEnter = useSelector((state) => state.telegram.initialEnter);
  const isDarkScheme = tg.colorScheme === "dark";

  useEffect(() => {
    if (tg.SettingsButton) {
      tg.SettingsButton.show();
      tg.SettingsButton.onClick(() => {
        if (hapticEnabled) tg.HapticFeedback.selectionChanged();
        navigate(paths.settings, { state: { from: pathname } });
      });
    }
  }, []);

  const isNoGameWrapper =
    pathname === paths.referral ||
    pathname === paths.settings ||
    pathname === paths.task ||
    pathname === paths.profile ||
    pathname === paths.giveaway ||
    pathname === paths.marketItem ||
    pathname === paths.inventory ||
    pathname === paths.transactions ||
    pathname === paths.roulette ||
    pathname === paths.heroDetail;

  const isNoGameInterface =
    pathname === paths.hero || pathname === paths.heroes;

  const isOnlyNavbar = pathname === paths.heroes;

  return {
    isInitialEnter,
    isDarkScheme,
    isNoGameWrapper,
    isNoGameInterface,
    isOnlyNavbar,
  };
};
