import React from "react";
import styles from "./styles.module.scss";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import cx from "classnames";

const SimpleTask = ({ handleSubmitTask, isButtonDisabled, task, t }) => {
  return (
    <TelegramButton
      disabled={task.status === "started" && isButtonDisabled}
      className={cx(styles.telegramButton, {
        [styles.disabledButton]: task.status === "started" && isButtonDisabled,
      })}
      onClick={handleSubmitTask}
    >
      {task.status === "started" ? t("Claim") : task.button_text}
    </TelegramButton>
  );
};

export default SimpleTask;
