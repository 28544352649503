import React from "react";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import styles from "./common.module.scss";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { Modal, Text } from "@telegram-apps/telegram-ui";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import { useTranslation } from "react-i18next";
import { AsyncPreloadImages } from "../../utils/asyncPreload";

const SuccessModal = ({
  isSuccessModalOpen,
  setIsSuccessModalOpen,
  handleOpenRoulette,
  activeItemImage,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isSuccessModalOpen}
      onOpenChange={(open) => {
        if (!open) setIsSuccessModalOpen(false);
      }}
      header={
        <ModalHeader
          className={styles.modalHeader}
          after={
            <ModalClose>
              <Icon28Close
                style={{ color: "var(--tgui--plain_foreground)" }}
                onClick={() => setIsSuccessModalOpen(false)}
              />
            </ModalClose>
          }
        />
      }
    >
      <div className={styles.successModalContent}>
        <Text>{t("Chest purchased! Time to see what's inside.")}</Text>
        <div className={styles.relative}>
          <img
            className={styles.image}
            src={AsyncPreloadImages.PURCHASED_ICON}
            alt={t("Purchased item")}
          />
          <img
            alt={"marketItem"}
            src={activeItemImage}
            className={styles.marketItemImage}
          />
        </div>
        <TelegramButton onClick={handleOpenRoulette}>
          {t("Open Now")}
        </TelegramButton>
      </div>
    </Modal>
  );
};

export default SuccessModal;
