import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Caption, Text } from "@telegram-apps/telegram-ui";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import { useTranslation } from "react-i18next";
import { AsyncPreloadImages } from "../../utils/asyncPreload";

const GiveawayNoLeaders = ({ giveaway }) => {
  const { t } = useTranslation();
  const [imagesLoaded, setImagesLoaded] = useState({
    noLeadersImg: false,
    coinImg: false,
  });

  const handleImageLoad = (imageName) => {
    setImagesLoaded((prev) => ({
      ...prev,
      [imageName]: true,
    }));
  };

  const allImagesLoaded = imagesLoaded.noLeadersImg && imagesLoaded.coinImg;

  return (
    <div className={`${styles.relative} ${styles.fadeIn}`}>
      {!allImagesLoaded && <LoaderComponent />}
      <div
        className={styles.images}
        style={{
          display: allImagesLoaded ? "flex" : "none",
        }}
      >
        <img
          className={`${styles.noLeadersImg} ${styles.fadeIn}`}
          alt={"noLeaders"}
          src={AsyncPreloadImages.NO_LEADERS_IN_GIVEAWAYS}
          onLoad={() => handleImageLoad("noLeadersImg")}
        />
        <img
          className={`${styles.coinImg} ${styles.fadeIn}`}
          src={giveaway.image_url}
          alt={"coin"}
          onLoad={() => handleImageLoad("coinImg")}
        />
      </div>
      {giveaway.status !== "completed" && (
        <>
          <Text>{t("No Participants Yet")}</Text>
          <Caption>{t("Be the first by inviting a friend!")}</Caption>
        </>
      )}
    </div>
  );
};

export default GiveawayNoLeaders;
