import React from "react";
import { Button as TelegramUIButton } from "@telegram-apps/telegram-ui";
import { useTelegram } from "../../../hooks/Layout/useTelegramHook";
import { useSelector } from "react-redux";

const TelegramButton = ({ onClick, ...props }) => {
  const { tg } = useTelegram();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const handleClick = (event) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();

    if (onClick) {
      onClick(event);
    }
  };

  return <TelegramUIButton {...props} onClick={handleClick} />;
};

export default TelegramButton;
