import { useEffect, useState } from "react";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import { getUserReferrals } from "../../services/referralService";
import { useTelegram } from "../Layout/useTelegramHook";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getReferralAmount } from "../../services/ratingService";
import useAndroidScrollFix from "../Layout/useAndroidScrollFix";
import { fetchUserData } from "../../services/registrationService";
import useLoaderHook from "../Layout/useLoaderHook";
import { useTranslation } from "react-i18next";

export const useReferralHook = () => {
  const location = useLocation();
  const taskId = location.state?.id;
  const backState = taskId ? { state: { id: taskId } } : {};

  useTelegramBackButton(-1, backState);
  useLoaderHook();
  useAndroidScrollFix();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [referralAmount, setReferralAmount] = useState(null);
  const [totalEntries, setTotalEntries] = useState(0);
  const telegramData = useSelector((state) => state.telegram.telegramData);
  const dispatch = useDispatch();
  const colorScheme = telegramData?.colorScheme;

  const { tg } = useTelegram();
  const { t } = useTranslation();
  const userData = useSelector((state) => state.telegram.user);

  const referralLink = `https://t.me/ThePathGameBot/discover?startapp=${userData?.id}`;

  const fetchReferrals = (page) => {
    return getUserReferrals(page, 10)
      .then((response) => {
        const data = response?.data?.data || [];
        const paginationInfo = response.data.pagination_info || {};
        setUsers((prevUsers) => [...prevUsers, ...data]);
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
        setTotalEntries(response?.data?.pagination_info?.total_entries || 0);
      })
      .catch((error) => console.error("Failed to fetch referrals:", error));
  };

  useEffect(() => {
    const fetchReferralAmount = async () => {
      try {
        const response = await getReferralAmount();
        setReferralAmount(response);
      } catch (error) {
        console.error("Failed to fetch referral amount:", error);
      }
    };
    fetchUserData(dispatch);
    fetchReferralAmount();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await fetchReferrals(page);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const handleCopyLink = () => {
    const textArea = document.createElement("textarea");
    textArea.value = referralLink;

    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = "0";
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
      console.log("Copying text command was " + msg);
      tg.HapticFeedback.notificationOccurred("success");
      handleShowSnackbar();
    } catch (err) {
      console.error("Failed to copy: ", err);
    }

    document.body.removeChild(textArea);
  };

  const handleShareLink = () => {
    const text = t("shareLink");
    const fullPath = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(text)}`;

    tg.HapticFeedback.selectionChanged();
    tg.openTelegramLink(fullPath);
  };

  const loadMoreReferrals = () => {
    if (hasMore && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleShowSnackbar = (message) => {
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return {
    users,
    isLoading,
    handleCopyLink,
    handleShareLink,
    referralAmount,
    loadMoreReferrals,
    page,
    totalEntries,
    colorScheme,
    snackbarOpen,
    handleCloseSnackbar,
  };
};
