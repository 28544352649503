import { apiUrl } from "../utils/globals";
import { axiosInstance, axiosListInstance } from "./axiosService";

const getGiveaways = (page, pageSize, status) => {
  return axiosListInstance.get(`${apiUrl}giveaway_activations`, {
    params: {
      status,
      order_by: status === "completed" ? "ended_at_desc" : "ended_at_asc",
      page,
      page_size: pageSize,
    },
  });
};

const getGiveaway = (id) => {
  return axiosInstance.get(`/giveaway_activations/${id}`);
};
const getGiveawayRating = (id) => {
  return axiosInstance.get(`/giveaway_activations/${id}/rating`);
};
const getMyGiveawayRating = (id) => {
  return axiosInstance.get(`/me/giveaway_activations/${id}/rating`);
};

export { getGiveaways, getGiveaway, getGiveawayRating, getMyGiveawayRating };
