import React from "react";
import styles from "./styles.module.scss";
import { Image } from "@telegram-apps/telegram-ui";
import { PreloadImages } from "../../utils/globals";

const CoinLoader = () => {
  return (
    <div className={styles.loader}>
      <Image className={styles.logo} size={60} src={PreloadImages.COIN_ICON} />
    </div>
  );
};

export default CoinLoader;
