import React from "react";
import styles from "./styles.module.scss";
import { Badge, Image, Info, Text } from "@telegram-apps/telegram-ui";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import MarketSnackbar from "./MarketSnackbar";
import { formatAmount } from "../../utils/formatNumbers";
import { PreloadImages } from "../../utils/globals";
import { useMarketHook } from "../../hooks/Pages/useMarketHook";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import MarketCard from "./MarketCard";
import MarketModal from "./MarketModal";
import SuccessModal from "./SuccessModal";
import cx from "classnames";

const Market = () => {
  const {
    handleMarketItemClick,
    handleBuyMarketItem,
    t,
    marketItems,
    isLoading,
    userAmount,
    snackbar,
    setSnackbar,
    activeId,
    isModalOpen,
    setIsModalOpen,
    activeModalItem,
    setActiveModalItem,
    handleNavigateToInventory,
    handleCardClick,
    handleChipClick,
    handleOpenRoulette,
    setIsSuccessModalOpen,
    isSuccessModalOpen,
    activeItemImage,
  } = useMarketHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={cx(styles.container, styles.fadeIn)}>
        <div className={styles.topline}>
          <Info type={"text"}>
            <div className={styles.userBalance}>
              {`${formatAmount(userAmount)}`}
              <Image
                size={25}
                className={styles.coinImage}
                src={PreloadImages.COIN_ICON}
              />
            </div>
          </Info>
          <Image
            size={40}
            onClick={handleNavigateToInventory}
            className={styles.image}
            src={AsyncPreloadImages.MY_TREASURES_ICON}
          />
        </div>
        {!marketItems.length && !isLoading && <Text>Беда...</Text>}
        <div className={cx(styles.marketItemsList, styles.fadeIn)}>
          {marketItems.map((item) => (
            <div style={{ position: "relative" }} key={item.id}>
              {item.sale && (
                <Badge className={styles.badge} mode="primary" type="number">
                  - {item.sale.discount_percentage}%
                </Badge>
              )}
              <MarketCard
                handleCardClick={handleCardClick}
                handleChipClick={handleChipClick}
                handleMarketItemClick={handleMarketItemClick}
                setActiveModalItem={setActiveModalItem}
                item={item}
                setIsModalOpen={setIsModalOpen}
                t={t}
              />
            </div>
          ))}
        </div>
        {snackbar.open && (
          <MarketSnackbar
            handleOpenRoulette={handleOpenRoulette}
            setSnackbar={setSnackbar}
            snackbar={snackbar}
            activeId={activeId}
          />
        )}
      </div>
      <MarketModal
        t={t}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        handleBuyMarketItem={handleBuyMarketItem}
        activeModalItem={activeModalItem}
      />
      <SuccessModal
        activeItemImage={activeItemImage}
        handleOpenRoulette={handleOpenRoulette}
        isSuccessModalOpen={isSuccessModalOpen}
        setIsSuccessModalOpen={setIsSuccessModalOpen}
      />
    </>
  );
};

export default Market;
