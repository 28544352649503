import React, { createContext, useContext, useEffect, useState } from "react";
import { Socket } from "phoenix";
import { socketApiUrl } from "./globals";

const SocketContext = createContext(null);

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children, telegramData, user }) => {
  const [socket, setSocket] = useState(null);
  const [channel, setChannel] = useState(null);
  const [lobbyChannel, setLobbyChannel] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (!telegramData?.initData || !user) return;

    const socketInstance = new Socket(`${socketApiUrl}/socket`, {
      params: { init_data: telegramData.initData },
    });

    socketInstance.connect();
    setSocket(socketInstance);

    const lobbyChannelInstance = socketInstance.channel("user:lobby", {});
    lobbyChannelInstance
      .join()
      .receive("ok", (resp) => {
        console.log("Joined lobby channel successfully", resp);
      })
      .receive("error", (resp) => {
        console.log("Unable to join lobby channel", resp);
      });

    lobbyChannelInstance.onMessage((event, payload) => {
      console.log(`Received message in lobby: ${event}`, payload);
      setMessages((prevMessages) => [...prevMessages, { event, payload }]);
    });

    setLobbyChannel(lobbyChannelInstance);

    return () => {
      if (channel) {
        leaveChannel(channel);
      }
      if (lobbyChannel) {
        leaveChannel(lobbyChannel);
      }
      socketInstance.disconnect();
    };
  }, [telegramData, user]);

  const joinChannel = (userId) => {
    if (channel || !socket) return;

    const channelInstance = socket.channel(`user:${userId}`, {});
    channelInstance
      .join()
      .receive("ok", (resp) => {
        console.log("Joined user channel successfully", resp);
      })
      .receive("error", (resp) => {
        console.log("Unable to join user channel", resp);
      });

    channelInstance.on("new_message", (payload) => {
      console.log(`Received new_message in user channel`, payload);
      setMessages((prevMessages) => [
        ...prevMessages,
        { event: "new_message", payload },
      ]);
    });

    setChannel(channelInstance);
  };

  const leaveChannel = (componentChannel) => {
    if (componentChannel) {
      componentChannel
        .leave()
        .receive("ok", (resp) => {
          console.log("Left channel successfully", resp);
          setChannel(null);
        })
        .receive("error", (resp) => {
          console.log("Unable to leave channel", resp);
        });
    }
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
        channel,
        joinChannel,
        leaveChannel,
        lobbyChannel,
        messages,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
