import { axiosInstance, axiosListInstance } from "./axiosService";
import { apiUrl } from "../utils/globals";

const getTasks = (page, pageSize) => {
  return axiosListInstance.get(`${apiUrl}me/tasks`, {
    params: {
      page: page,
      page_size: pageSize,
    },
  });
};

const getTaskById = (id) => {
  return axiosInstance.get(`/me/tasks/${id}`);
};

const startTask = (id) => {
  return axiosInstance.post(`/me/tasks/${id}/start`);
};

const getTaskStatus = (id) => {
  return axiosInstance.get(`/me/tasks/${id}/finish`);
};

const finishTask = (id) => {
  return axiosInstance.post(`/me/tasks/${id}/finish`);
};

export { getTasks, getTaskById, startTask, finishTask, getTaskStatus };
