import React from "react";
import styles from "./styles.module.scss";
import { getCardImagesByRarity } from "../../../utils/imagesGetter";
import cx from "classnames";

const HeroCard = ({
  character,
  allLoaded,
  isImageLoaded,
  handleImageLoad,
  handleImageError,
  handleOpenDetailPage,
}) => {
  return (
    <div
      onClick={handleOpenDetailPage}
      className={cx(styles.imagesWrapper, {
        [styles.visible]: allLoaded,
      })}
    >
      <img
        alt="mainCard"
        src={getCardImagesByRarity(character?.rarity_level).FRONT.MAIN}
        className={cx(styles.cardWrapper, {
          [styles.hidden]: !isImageLoaded.mainCard,
        })}
        onLoad={() => handleImageLoad("mainCard")}
        onError={() => handleImageError("mainCard")}
      />
      <img
        alt="texture"
        src={getCardImagesByRarity(character?.rarity_level).FRONT.TEXTURE}
        className={cx(styles.cardTexture, {
          [styles.hidden]: !isImageLoaded.texture,
        })}
        onLoad={() => handleImageLoad("texture")}
        onError={() => handleImageError("texture")}
      />
      <img
        className={cx(styles.heroImg, {
          [styles.hidden]: !isImageLoaded.hero,
        })}
        alt="hero"
        src={character.hero_preview_url}
        onLoad={() => handleImageLoad("hero")}
        onError={() => handleImageError("hero")}
      />
    </div>
  );
};

export default HeroCard;
