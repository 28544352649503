import { useEffect } from "react";

export function useTelegram() {
  const tg = window.Telegram.WebApp;

  useEffect(() => {
    tg.ready();
  }, [tg]);

  return {
    tg,
  };
}
