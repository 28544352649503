import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  telegramData: null,
  user: null,
  isGame: true,
  hapticEnabled: false,
  username: "",
  initialEnter: true,
  userLanguage: "en",
  isPlaying: false,
  volume: 0.1,
};

const telegramSlice = createSlice({
  name: "telegram",
  initialState,
  reducers: {
    setTelegramData: (state, action) => {
      state.telegramData = action.payload;
    },
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setUserId: (state, action) => {
      state.loginUser = action.payload;
    },
    setIsGame: (state, action) => {
      state.isGame = action.payload;
    },
    setHapticEnabled(state, action) {
      state.hapticEnabled = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setInitialEnter: (state, action) => {
      state.initialEnter = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
    setVolume: (state, action) => {
      state.volume = action.payload;
    },
  },
});

export const {
  setTelegramData,
  setUserData,
  setIsGame,
  setHapticEnabled,
  setUsername,
  setInitialEnter,
  setIsPlaying,
  setVolume,
  setUserId,
} = telegramSlice.actions;
export default telegramSlice.reducer;
