import React from "react";
import { Snackbar } from "@telegram-apps/telegram-ui";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import { AiOutlineCheck } from "react-icons/ai";
import { MdError } from "react-icons/md";
import { useTranslation } from "react-i18next";

const MarketSnackbar = ({ snackbar, setSnackbar, handleOpenRoulette }) => {
  const { t } = useTranslation();
  return (
    <Snackbar
      duration={3000}
      open={snackbar.open}
      onClose={() => setSnackbar({ open: false, type: "" })}
      before={
        snackbar.type === "success" ? (
          <AiOutlineCheck color="green" />
        ) : (
          <MdError color="red" />
        )
      }
      after={
        snackbar.type === "success" && (
          <TelegramButton onClick={handleOpenRoulette}>
            {t("Open")}
          </TelegramButton>
        )
      }
      description={
        snackbar.type === "success"
          ? t("Your purchase was successful")
          : t("Your purchase failed")
      }
    >
      {snackbar.type === "success" ? t("You're all set") : t("Error")}
    </Snackbar>
  );
};

export default MarketSnackbar;
