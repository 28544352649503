import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import paths from "../../pages/paths";
import { useTelegram } from "../Layout/useTelegramHook";
import { useDispatch } from "react-redux";
import { fetchUserData } from "../../services/registrationService";
import { getHero } from "../../services/heroService";

export const useHeroDetailHook = () => {
  const location = useLocation();
  const hero = location.state.hero;
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [activeSkill, setActiveSkill] = useState(null);
  const [heroData, setHeroData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchUserData(dispatch);
    if (!hero) {
      navigate(paths.hero);
      return;
    }
    getHero(hero.id)
      .then((data) => {
        setHeroData(data);
        setActiveSkill(data.skills[0]);
      })
      .finally(() => setIsLoading(false));
  }, [hero, dispatch]);

  const [searchParams, _] = useSearchParams();

  const queryParams = {
    page: String(searchParams.get("page") || 1),
    order_by: String(searchParams.get("order_by") || "total_stats_desc"),
    class_ids: String(searchParams.get("class_ids") || ""),
    hero_ids: String(searchParams.get("hero_ids") || ""),
    rarity_level_ids: String(searchParams.get("rarity_level_ids") || ""),
  };

  const queryString = new URLSearchParams(queryParams).toString();

  useTelegramBackButton(`${paths.hero}?${queryString}`, {
    state: { character: hero },
  });

  const handlePrevPage = () => {
    if (!heroData) return;
    setCurrentPage((prev) => {
      const newPage = prev === 0 ? 1 : 0;
      const skillsOnNewPage = heroData.skills.slice(
        newPage * skillsPerPage,
        (newPage + 1) * skillsPerPage,
      );
      setActiveSkill(skillsOnNewPage[skillsOnNewPage.length - 1]);
      return newPage;
    });
  };

  const handleNextPage = () => {
    if (!heroData) return;
    setCurrentPage((prev) => {
      const newPage = prev === 1 ? 0 : 1;
      const skillsOnNewPage = heroData.skills.slice(
        newPage * skillsPerPage,
        (newPage + 1) * skillsPerPage,
      );
      setActiveSkill(skillsOnNewPage[0]);
      return newPage;
    });
  };

  const handleSkillClick = (skill) => {
    setActiveSkill(skill);
  };

  const skillsPerPage = 5;
  const displayedSkills = heroData
    ? heroData.skills.slice(
        currentPage * skillsPerPage,
        (currentPage + 1) * skillsPerPage,
      )
    : [];

  const getColorByClassname = (classname) => {
    const colors = {
      common: "saddlebrown",
      rare: "gray",
      elite: "orange",
      legendary: "cornflowerblue",
      divine: "rgb(50, 229, 94)",
    };

    return colors[classname] || "black";
  };

  const { tg } = useTelegram();
  const isDarkScheme = tg.colorScheme === "dark";

  return {
    activeSkill,
    handlePrevPage,
    handleNextPage,
    handleSkillClick,
    displayedSkills,
    getColorByClassname,
    isDarkScheme,
    heroData,
    isLoading,
  };
};
