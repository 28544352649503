import React, { useState } from "react";
import Registration from "../../../pages/Registration/Registration";
import Guide from "../Guide/Guide";

const RegistrationWrapper = () => {
  const [isRegistration, setIsRegistration] = useState(true);

  const handleSetGuide = () => {
    setIsRegistration(false);
  };

  return (
    <>
      {isRegistration && <Registration handleSetGuide={handleSetGuide} />}
      {!isRegistration && <Guide />}
    </>
  );
};

export default RegistrationWrapper;
