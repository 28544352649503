import React, { useEffect, useState } from "react";
import { getMeMarketItems } from "../../services/marketService";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../services/registrationService";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import styles from "./styles.module.scss";
import { Caption, Card, Text } from "@telegram-apps/telegram-ui";
import { CardCell } from "@telegram-apps/telegram-ui/dist/components/Blocks/Card/components/CardCell/CardCell";
import useTelegramBackButton from "../../hooks/Layout/useTelegramBackButton";
import paths from "../paths";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import { useTelegram } from "../../hooks/Layout/useTelegramHook";
import cx from "classnames";
import PageTitle from "../../components/PageTitle/PageTitle";

const Inventory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useTelegramBackButton(-1);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();
  const navigate = useNavigate();

  const [marketItems, setMarketItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    if (!isLoading) setIsLoading(true);
    getMeMarketItems()
      .then((data) => {
        setMarketItems(data);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchUserData(dispatch);
    getData();
  }, []);

  const navigateToRoulette = (id) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.roulette, { state: { id } });
  };

  const navigateToMarket = () => {
    navigate(paths.market);
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={cx(styles.inventoryWrapper, styles.fadeIn)}>
      <PageTitle title={"Inventory"} />
      {!marketItems.length && (
        <div className={cx(styles.noData, styles.fadeIn)}>
          <img
            alt={t("No Treasures Yet")}
            src={AsyncPreloadImages.NO_TREASURES_ICON}
            className={styles.noDataImage}
          />
          <Text>{t("No Treasures Yet")}</Text>
          <Caption>
            {t(
              "As soon as you acquire chests, they'll appear here for you to explore.",
            )}
          </Caption>
          <TelegramButton onClick={navigateToMarket}>
            {t("Get a Chest")}
          </TelegramButton>
        </div>
      )}
      {!!marketItems.length && (
        <div className={cx(styles.marketItemsList, styles.fadeIn)}>
          {marketItems.map((item) => (
            <Card
              key={item.id}
              className={styles.card}
              onClick={() => navigateToRoulette(item.id)}
            >
              <img
                className={styles.cardImg}
                alt={item.market_item.title}
                src={item.market_item.image_url}
              />
              <CardCell className={styles.cardCell} readOnly multiline>
                <span className={styles.text}>{item.market_item.title}</span>
              </CardCell>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default Inventory;
