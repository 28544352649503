import React from "react";
import styles from "./styles.module.scss";
import Navbar from "../Navbar/Navbar";
import { PreloadImages } from "../../../utils/globals";
import Header from "../Header/Header";
import { useDefaultLayoutHook } from "../../../hooks/Layout/useDefaultLayoutHook";

const DefaultLayout = ({ children }) => {
  const {
    isInitialEnter,
    isDarkScheme,
    isNoGameWrapper,
    isNoGameInterface,
    isOnlyNavbar,
  } = useDefaultLayoutHook();

  return (
    <div
      style={{
        backgroundImage: isNoGameWrapper
          ? ``
          : `url(${PreloadImages.BACKGROUND_IMAGE})`,
      }}
      className={styles.wrapper}
    >
      {!isNoGameWrapper && isDarkScheme && (
        <div className={styles.overlay}></div>
      )}
      {!isNoGameWrapper && !isInitialEnter && !isNoGameInterface && <Header />}
      <div className={styles.content}>{children}</div>
      {((!isNoGameWrapper && !isInitialEnter && !isNoGameInterface) ||
        isOnlyNavbar) && <Navbar />}
    </div>
  );
};

export default DefaultLayout;
