import React from "react";
import styles from "./styles.module.scss";
import { useRatingPageHook } from "../../hooks/Pages/useRatingPageHook";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import RatingCell from "./RatingCell";
import PageTitle from "../../components/PageTitle/PageTitle";

const Rating = () => {
  const { leaders, isLoading, userData } = useRatingPageHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={`${styles.container} ${styles.fadeIn}`}>
      <PageTitle title={"leaderboard"} />
      <div className={styles.listWrapper}>
        <div className={styles.list}>
          {leaders.map((user, index) => (
            <RatingCell key={index} user={user} />
          ))}
        </div>
      </div>
      <RatingCell user={userData} isCurrentUser />
    </div>
  );
};

export default Rating;
