import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./utils/i18n";
import "@telegram-apps/telegram-ui/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider, useDispatch } from "react-redux";
import { store } from "./store/store";
import App from "./App";
import { setTelegramData, setUserData } from "./store/slices/telegramSlice";
import { AppRoot } from "@telegram-apps/telegram-ui";
import i18n from "i18next";

const root = ReactDOM.createRoot(document.getElementById("root"));

const InitApp = () => {
  const [isTg, setIsTg] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      const params = new URLSearchParams(tg.initData);
      const user = JSON.parse(decodeURIComponent(params.get("user")));
      const parsedData = {
        initData: tg.initData,
        user,
        platform: tg.platform,
        theme: tg.themeParams,
        colorScheme: tg.colorScheme,
      };
      const isUserFromTelegram = !!user?.id;
      setIsTg(isUserFromTelegram);

      if (!isUserFromTelegram) {
        window.location.href = "https://t.me/ThePathGameBot/discover";
      } else {
        dispatch(setTelegramData(parsedData));
        dispatch(setUserData(parsedData.user));

        const userLanguage = user.language_code || "en";
        i18n.changeLanguage(userLanguage);
      }
    } else {
      setIsTg(false);
    }
  }, [dispatch]);

  if (isTg === null) {
    return null;
  }

  if (!isTg) {
    return null;
  }

  return <App />;
};

root.render(
  <Provider store={store}>
    <AppRoot style={{ height: "100%" }}>
      <InitApp />
    </AppRoot>
  </Provider>,
);
