import { getImageUrl } from "./constants";

export const maxHeroCount = 5;

export const apiUrl = process.env.REACT_APP_API_URL;
export const socketApiUrl = process.env.REACT_APP_SOCKET_URL;

export const RegistrationPreload = {
  SILHOUETTE_1: `${getImageUrl()}icons/welcome_man.webp`,
  COINS_BACKGROUND: `${getImageUrl()}icons/coin_fall.webp`,
};

export const MusicPreload = {
  MUSIC: `https://s3.pathgame.app/public/config/path_1.wav`,
};

export const PreloadImages = {
  NAVBAR_HOME: `${getImageUrl()}navbar/home.webp`,
  NAVBAR_TASKS: `${getImageUrl()}navbar/quests.webp`,
  NAVBAR_RATING: `${getImageUrl()}navbar/rating.webp`,
  NAVBAR_SHOP: `${getImageUrl()}navbar/shop.webp`,

  GRAY_BUTTON: `${getImageUrl()}buttons/button_grey.webp`,
  BLACK_BUTTON: `${getImageUrl()}buttons/button_black.webp`,
  FANCY_BUTTON: `${getImageUrl()}buttons/button_fancy.webp`,

  COIN_ICON: `${getImageUrl()}coins/pathcoin.webp`,
  WALLET_ICON: `${getImageUrl()}icons/wallet.webp`,

  PROFILE_DARK: `${getImageUrl()}profiles/profile_dark.webp`,
  PROFILE_LIGHT: `${getImageUrl()}profiles/profile_white.webp`,

  REFERRAL_ICON: `${getImageUrl()}icons/referral.webp`,
  MUSIC_ICON_ON: `${getImageUrl()}icons/music_on.webp`,
  MUSIC_ICON_OFF: `${getImageUrl()}icons/music_off.webp`,

  BACKGROUND_IMAGE: `${getImageUrl()}icons/game_layout.webp`,
};
