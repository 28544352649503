import React from "react";
import styles from "./styles.module.scss";
import {
  Avatar,
  Cell,
  Image,
  Info,
  List,
  Text,
} from "@telegram-apps/telegram-ui";
import Countdown from "react-countdown";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import { formatAmount } from "../../utils/formatNumbers";
import GiveawayNoLeaders from "./GiveawayNoLeaders";
import { getLeaderImage } from "../../utils/imagesGetter";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useGiveawayHook } from "../../hooks/Pages/useGiveawayHook";

const Giveaway = () => {
  const {
    giveaway,
    ratingList,
    isLoading,
    userData,
    isRatingLoading,
    countdownTimestamp,
    t,
  } = useGiveawayHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={`${styles.wrapper} ${styles.fadeIn}`}>
      <Cell
        multiline
        style={{ maxHeight: "min-content" }}
        className={`${styles.cell} ${styles.fadeIn}`}
        before={
          <Avatar
            className={styles.avatar}
            src={giveaway.image_url}
            size={76}
          />
        }
        subtitle={
          <span
            className={styles.info}
          >{`${formatAmount(giveaway.pool_amount)} ${giveaway.pool_currency}`}</span>
        }
      >
        {giveaway.title}
      </Cell>
      <PageTitle isNoMargin>
        {giveaway.status === "completed" ? (
          `${t("This giveaway has ended")} ${new Date(giveaway.ended_at)
            .toLocaleString("default", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/\//g, ".")}`
        ) : (
          <Countdown date={countdownTimestamp} />
        )}
      </PageTitle>
      <Cell
        style={{ maxHeight: "min-content" }}
        className={`${styles.cell} ${styles.fadeIn}`}
        subhead={t("DESCRIPTION")}
        multiline
      >
        {giveaway.description}
      </Cell>
      <SectionHeader className={`${styles.fadeIn} ${styles.header}`}>
        {t("LEADERBOARD")}
      </SectionHeader>
      {isRatingLoading && (
        <div className={styles.loading}>
          <LoaderComponent />
        </div>
      )}
      {!isRatingLoading && !ratingList.length && (
        <GiveawayNoLeaders giveaway={giveaway} />
      )}
      {!isRatingLoading && !!ratingList.length && (
        <List className={styles.list}>
          {ratingList.map((rating) => (
            <Cell
              key={rating.id}
              className={`${styles.cell} ${styles.fadeIn}`}
              after={
                <Info type="text">{`${formatAmount(Math.round(rating.prize))} ${giveaway.pool_currency}`}</Info>
              }
              description={`${rating.referral_count} ${t("Invited")}`}
              before={
                <Image
                  src={getLeaderImage(rating?.rank)}
                  className={`${styles.image} ${
                    rating?.rank < 2 && styles.leaderImage
                  }`}
                  size={48}
                >
                  {rating?.rank > 3 && (
                    <Text className={styles.text}>{rating?.rank || "-"}</Text>
                  )}
                </Image>
              }
            >
              {rating?.username || "-"}
            </Cell>
          ))}
        </List>
      )}
      {userData && (
        <Cell
          key={userData?.id}
          className={`${styles.cell} ${styles.customCell}`}
          after={
            <Info type="text">{`${formatAmount(Math.round(userData?.prize))} ${giveaway.pool_currency}`}</Info>
          }
          description={`${t("Invited")} ${userData?.referral_count} `}
          before={
            <Image
              src={getLeaderImage(userData?.rank)}
              className={`${styles.image} ${
                userData?.rank < 2 && styles.leaderImage
              }`}
              size={48}
            >
              {userData?.rank > 3 && (
                <Text className={styles.text}>{userData?.rank || "-"}</Text>
              )}
            </Image>
          }
        >
          {userData?.username || "-"}
        </Cell>
      )}
    </div>
  );
};

export default Giveaway;
