import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Game from "./pages/Game";
import RegistrationWrapper from "./components/ForRegistration/RegistrationWrapper/RegistrationWrapper";
import usePreloadImages from "./hooks/Layout/usePreloadImages";
import { ToastContainer } from "react-toastify";
import { Progress, Text } from "@telegram-apps/telegram-ui";
import useAudioHook from "./hooks/Layout/useAudioHook";
import { PreloadImages } from "./utils/globals";
import CoinLoader from "./components/CoinLoader/CoinLoader";
import { SocketProvider } from "./utils/socketContext";
import LoaderComponent from "./components/common/LoaderComponent/LoaderComponent";

const App = () => {
  const progress = usePreloadImages(PreloadImages);
  const isGame = useSelector((state) => state.telegram.isGame);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useAudioHook({ loadingComplete });

  const telegramData = useSelector((state) => state.telegram.telegramData);
  const user = telegramData?.user;

  useEffect(() => {
    if (progress === 100) {
      setLoadingComplete(true);
    }
  }, [progress]);

  if (!loadingComplete) {
    return (
      <>
        <Progress value={progress} />
        <CoinLoader />
      </>
    );
  }

  if (false) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoaderComponent />
        <Text>Please, Stand By</Text>
      </div>
    );
  }

  return (
    <SocketProvider telegramData={telegramData} user={user}>
      <ToastContainer />
      {!isGame && <RegistrationWrapper />}
      {isGame && <Game />}
    </SocketProvider>
  );
};

export default App;
