import React from "react";
import styles from "./styles.module.scss";
import Iframe from "react-iframe";
import { apiUrl } from "../../utils/globals";

const InfoModal = ({ handleCloseModal }) => {
  const is = apiUrl === "https://api.pathgame.app/api/v1/";
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={handleCloseModal}>
          &times;
        </button>
        <Iframe
          url={
            is
              ? "https://pathgame.app/wiki"
              : "https://ebat-game-dev.ebat.me/wiki"
          }
          className={styles.iframe}
          display="block"
          position="relative"
        />
      </div>
    </div>
  );
};

export default InfoModal;
