import React, { useEffect } from "react";
import useLoaderHook from "../../hooks/Layout/useLoaderHook";
import { useTelegram } from "../../hooks/Layout/useTelegramHook";

const ErrorPage = () => {
  const { tg } = useTelegram();
  useLoaderHook();

  useEffect(() => {
    tg.BackButton.hide();
  }, []);

  return <div style={{ margin: "auto" }}>Error</div>;
};

export default ErrorPage;
