import { useEffect, useRef } from "react";
import { MusicPreload } from "../../utils/globals";
import { Howl } from "howler";
import { setIsPlaying } from "../../store/slices/telegramSlice";
import { useDispatch, useSelector } from "react-redux";

const useAudioHook = ({ loadingComplete }) => {
  const isPlaying = useSelector((state) => state.telegram.isPlaying);
  const volume = useSelector((state) => state.telegram.volume);
  const dispatch = useDispatch();
  const soundRef = useRef(null);
  useEffect(() => {
    if (loadingComplete) {
      soundRef.current = new Howl({
        src: [MusicPreload.MUSIC],
        loop: true,
        volume: volume,
        onloaderror: (id, error) => {
          console.error("Error loading audio:", error);
          dispatch(setIsPlaying(false));
        },
        onplayerror: (id, error) => {
          console.error("Error playing audio:", error);
          dispatch(setIsPlaying(false));
        },
      });
    }
  }, [loadingComplete, dispatch]);

  useEffect(() => {
    if (soundRef.current) {
      if (isPlaying) {
        soundRef.current.play();
      } else {
        soundRef.current.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (soundRef.current) {
      soundRef.current.volume(volume);
    }
  }, [volume]);
};

export default useAudioHook;
