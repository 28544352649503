import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { useNavigate, useSearchParams } from "react-router-dom";
import paths from "../../../pages/paths";
import {
  getCardImagesByRarity,
  getClassIcon,
} from "../../../utils/imagesGetter";
import { useSelector } from "react-redux";
import { useTelegram } from "../../../hooks/Layout/useTelegramHook";

const MiniCard = ({ character, delay }) => {
  const [loadedImages, setLoadedImages] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState({
    hero: false,
    texture: false,
    miniCard: false,
  });
  const navigate = useNavigate();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay]);

  const [shouldAnimate, setShouldAnimate] = useState(false);
  const nameRef = useRef(null);

  useEffect(() => {
    if (nameRef.current) {
      if (nameRef.current.scrollWidth > 50) {
        setShouldAnimate(true);
      }
    }
  }, [character?.hero_name]);

  const handleImageLoad = (imageName) => {
    setLoadedImages((prev) => prev + 1);
    setIsImageLoaded((prev) => ({
      ...prev,
      [imageName]: true,
    }));
  };

  const handleImageError = (imageName) => {
    setLoadedImages((prev) => prev + 1);
    setIsImageLoaded((prev) => ({
      ...prev,
      [imageName]: false,
    }));
  };

  const totalImages = 3;
  const allImagesLoaded = loadedImages === totalImages;

  useEffect(() => {
    console.log(loadedImages);
  }, [loadedImages]);

  const [searchParams, _] = useSearchParams();

  const queryParams = {
    page: String(searchParams.get("page") || 1),
    order_by: String(searchParams.get("order_by") || "total_stats_desc"),
    class_ids: String(searchParams.get("class_ids") || ""),
    hero_ids: String(searchParams.get("hero_ids") || ""),
    rarity_level_ids: String(searchParams.get("rarity_level_ids") || ""),
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const handleHeroClick = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(`${paths.hero}?${queryString}`, {
      state: { character: character },
    });
  };

  return (
    <div
      className={cx(styles.miniCardWrapper, {
        [styles.visible]: isVisible && allImagesLoaded,
      })}
      onClick={handleHeroClick}
    >
      <div className={styles.miniCard}>
        <img
          alt={"hero"}
          src={character.hero_preview_url}
          className={cx(styles.heroImage, {
            [styles.hidden]: !isImageLoaded.hero,
          })}
          onLoad={() => handleImageLoad("hero")}
          onError={() => handleImageError("hero")}
        />
        <img
          alt={"texture"}
          src={
            getCardImagesByRarity(character?.rarity_level).FRONT.TEXTURE_PREVIEW
          }
          className={cx(styles.heroTexture, {
            [styles.hidden]: !isImageLoaded.texture,
          })}
          onLoad={() => handleImageLoad("texture")}
          onError={() => handleImageError("texture")}
        />
        <img
          alt={"miniCard"}
          src={getCardImagesByRarity(character?.rarity_level).FRONT.PREVIEW}
          className={cx(styles.heroMiniCard, {
            [styles.hidden]: !isImageLoaded.miniCard,
          })}
          onLoad={() => handleImageLoad("miniCard")}
          onError={() => handleImageError("miniCard")}
        />
      </div>
      <div className={styles.heroTitle}>
        <img
          alt={"class"}
          src={character.class_image_url}
          width={"20px"}
          height={"20px"}
        />
        <div className={cx(styles.name, { [styles.animate]: shouldAnimate })}>
          <span ref={nameRef}>{character?.hero_name}</span>
        </div>
        <span className={styles.level}>{character.level}</span>
      </div>
    </div>
  );
};

export default MiniCard;
