import React from "react";
import styles from "./styles.module.scss";
import CoinLoader from "../../CoinLoader/CoinLoader";

const LoaderComponent = () => {
  return (
    <div className={styles.loader}>
      <CoinLoader />
    </div>
  );
};

export default LoaderComponent;
