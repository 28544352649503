import React from "react";
import styles from "./styles.module.scss";
import {
  Avatar,
  Caption,
  Cell,
  List,
  Modal,
  Navigation,
  Snackbar,
  Text,
} from "@telegram-apps/telegram-ui";
import { PreloadImages } from "../../utils/globals";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import "react-toastify/dist/ReactToastify.css";
import { useProfileHook } from "../../hooks/Pages/useProfileHook";
import { formatAmount } from "../../utils/formatNumbers";
import { Icon24Cancel } from "@telegram-apps/telegram-ui/dist/icons/24/cancel";
import { SiTon } from "react-icons/si";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { FiCopy } from "react-icons/fi";
import { AsyncPreloadImages } from "../../utils/asyncPreload";

const Profile = () => {
  const {
    isLoading,
    userAmount,
    userName,
    userRating,
    colorScheme,
    userWallet,
    truncateAddress,
    handleDeleteWallet,
    handleOpenConnectModal,
    handleCopyAddress,
    snackbarOpen,
    handleCloseSnackbar,
    handleOpenTransactions,
    handleOpenTreasures,
    t,
  } = useProfileHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={`${styles.wrapper} ${styles.fadeIn}`}>
        <Cell
          className={`${styles.topCell} ${styles.fadeIn}`}
          before={
            <Avatar
              className={styles.topAvatar}
              size={96}
              src={
                colorScheme !== "dark"
                  ? PreloadImages.PROFILE_DARK
                  : PreloadImages.PROFILE_LIGHT
              }
            />
          }
          subtitle={`${t("rank")} ${userRating || "-"}`}
        >
          {userName}
        </Cell>
        <List className={`${styles.list} ${styles.fadeIn}`}>
          <Cell
            className={`${styles.cell} ${styles.fadeIn}`}
            after={
              <Text className={styles.title}>{formatAmount(userAmount)}</Text>
            }
            before={
              <Avatar
                size={40}
                className={styles.avatar}
                src={PreloadImages.COIN_ICON}
              />
            }
            subtitle={t("path")}
          >
            <span className={styles.title}>{t("pathCoin")}</span>
          </Cell>
          <Cell
            onClick={handleOpenTreasures}
            className={`${styles.cell} ${styles.fadeIn} ${styles.walletCell}`}
            after={<Navigation />}
            before={
              <Avatar
                size={40}
                className={styles.avatar}
                src={AsyncPreloadImages.MY_TREASURES_ICON}
              />
            }
          >
            <Text>{t("Inventory")}</Text>
          </Cell>
          <Cell
            onClick={handleOpenTransactions}
            className={`${styles.cell} ${styles.fadeIn} ${styles.walletCell}`}
            after={<Navigation />}
            before={
              <Avatar
                size={40}
                className={styles.avatar}
                src={AsyncPreloadImages.TRANSACTIONS_HISTORY_ICON}
              />
            }
          >
            <Text>{t("Transaction history")}</Text>
          </Cell>
          <div className={`${styles.walletWrapper} ${styles.fadeIn}`}>
            {userWallet && (
              <Cell
                after={
                  <Modal
                    className={styles.modalWrapper}
                    header={
                      <ModalHeader
                        after={
                          <ModalClose>
                            <Icon28Close
                              style={{ color: "var(--tgui--plain_foreground)" }}
                            />
                          </ModalClose>
                        }
                      />
                    }
                    trigger={
                      <div>
                        <Icon24Cancel className={styles.deleteIcon} />
                      </div>
                    }
                  >
                    <div className={styles.modalContent}>
                      <Text>{t("unlink_wallet_confirmation")}</Text>
                      <TelegramButton onClick={handleDeleteWallet}>
                        {t("unlink")}
                      </TelegramButton>
                    </div>
                  </Modal>
                }
                className={`${styles.cell} ${styles.fadeIn}`}
                before={
                  <Avatar
                    size={40}
                    className={styles.avatar}
                    src={PreloadImages.WALLET_ICON}
                  />
                }
              >
                <div className={styles.walletRow}>
                  <Text>{truncateAddress(userWallet)}</Text>
                  <FiCopy
                    onClick={handleCopyAddress}
                    className={styles.copy}
                    size={24}
                  />
                </div>
              </Cell>
            )}
            {!userWallet && (
              <div className={styles.tonButtonWrapper}>
                <TelegramButton onClick={handleOpenConnectModal}>
                  <div className={styles.tonButton}>
                    <SiTon />
                    <Caption>{t("connect_wallet")}</Caption>
                  </div>
                </TelegramButton>
              </div>
            )}
          </div>
        </List>
      </div>
      {snackbarOpen && (
        <Snackbar
          className={styles.snackbar}
          before={<FiCopy size={20} />}
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          description={t("Copied to clipboard")}
        />
      )}
    </>
  );
};

export default Profile;
