import { axiosInstance } from "./axiosService";

const updateUserWallet = (wallet) => {
  return axiosInstance.patch("/me", {
    user_settings: {
      ton_wallet_address: wallet,
    },
  });
};
const updateUserLanguage = (language) => {
  if (!language) return;
  return axiosInstance.patch("/me", {
    user_settings: {
      language_code: language,
    },
  });
};

const updateUserSettings = (user_settings) => {
  return axiosInstance.put("/me", {
    user_settings,
  });
};

export { updateUserWallet, updateUserSettings, updateUserLanguage };
