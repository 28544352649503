import React from "react";
import styles from "./styles.module.scss";
import { Caption, Modal, Text } from "@telegram-apps/telegram-ui";
import {
  getCharImagesByRarity,
  getColorByClassName,
} from "../../../utils/imagesGetter";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { AsyncPreloadImages } from "../../../utils/asyncPreload";
import { useTranslation } from "react-i18next";

const CustomModal = ({ trigger, content }) => (
  <Modal
    header={
      <ModalHeader
        className={styles.header}
        after={
          <ModalClose>
            <Icon28Close className={styles.closeIcon} />
          </ModalClose>
        }
      />
    }
    trigger={trigger}
  >
    {content}
  </Modal>
);

const HeroCardTopRow = ({ character }) => {
  const { t } = useTranslation(); // Инициализация useTranslation

  console.log(
    "-----------------",
    getCharImagesByRarity(character?.rarity_level),
    character?.rarity_level,
  );

  return (
    <>
      <CustomModal
        trigger={
          <div className={styles.levelRow}>
            <img
              alt={"levelIcon"}
              src={AsyncPreloadImages.LEVEL_ICON}
              width={"50px"}
              height={"50px"}
            />
            <Text className={styles.level}>{character.level}</Text>
          </div>
        }
        content={
          <div className={styles.heroInfo}>
            <Text className={styles.heroName}>{character.hero_name}</Text>
            <img
              className={styles.heroImage}
              src={character.hero_preview_url}
              alt={"hero"}
            />
            <div className={styles.heroInfoRow}>
              <Caption>{t("Level")}:</Caption>
              <Caption className={styles.levelDigit}>{character.level}</Caption>
            </div>
            <Caption>{`${t("Class")}: ${character.class}`}</Caption>
            <div className={styles.heroInfoRow}>
              <Caption>{t("Rarity")}:</Caption>
              <Caption
                style={{
                  color: getColorByClassName(
                    character?.rarity_level?.toLowerCase(),
                  ),
                }}
              >
                {character?.rarity_level_name}
              </Caption>
            </div>
            <div className={styles.rarityRow}>
              <Caption>{t("Characteristic Roll Range")}:</Caption>
              <Caption
                style={{
                  color: getColorByClassName(
                    character?.rarity_level?.toLowerCase(),
                  ),
                }}
              >
                {`${character?.rarity_level_characteristic_min} - ${character.rarity_level_characteristic_max}`}
              </Caption>
            </div>
            <Caption>{character?.rarity_level_description}</Caption>
          </div>
        }
      />
      <CustomModal
        trigger={
          <img
            className={styles.classIcon}
            alt={"classIcon"}
            src={character.class_image_url}
            width={"50px"}
            height={"50px"}
          />
        }
        content={
          <div className={styles.classContent}>
            <Text className={styles.classTitle}>{character.class}</Text>
            <img
              alt={"classIcon"}
              src={character.class_image_url}
              width={"100px"}
              height={"100px"}
            />
            <div className={styles.characteristicRow}>
              <Caption
                className={styles.mainCharacteristic}
              >{`${t("Main characteristic")}: ${t(character.class_main_characteristic)}`}</Caption>
              <img
                height={"20px"}
                width={"20px"}
                className={styles.class_main_characteristic}
                alt={character.id}
                src={getCharImagesByRarity(
                  character?.class_main_characteristic,
                )}
              />
            </div>
            <Caption>{character.class_description}</Caption>
          </div>
        }
      />
    </>
  );
};

export default HeroCardTopRow;
