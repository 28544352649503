import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Text } from "@telegram-apps/telegram-ui";
import { useTranslation } from "react-i18next";
import { AsyncPreloadImages } from "../../utils/asyncPreload";

const NoData = () => {
  const { t } = useTranslation();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = AsyncPreloadImages.NO_GIVEAWAYS_IMG;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  if (!imageLoaded) {
    return null;
  }

  return (
    <div className={styles.noData}>
      <img
        className={styles.noGiveawayImage}
        alt={"noGiveaways"}
        src={AsyncPreloadImages.NO_GIVEAWAYS_IMG}
      />
      <Text>{t("No Giveaways")}</Text>
    </div>
  );
};

export default NoData;
