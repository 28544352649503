import { axiosInstance } from "./axiosService";
import {
  setUsername,
  setHapticEnabled,
  setIsGame,
  setInitialEnter,
  setUserId,
} from "../store/slices/telegramSlice";
import i18n from "i18next";

const registerUser = (userData) => {
  return axiosInstance.post("/registration", userData);
};

const getUser = () => {
  return axiosInstance.get("/me");
};

const fetchUserData = (dispatch) => {
  return getUser()
    .then((response) => {
      const {
        username,
        user_settings: { is_haptic_feedback_enabled, language_code },
      } = response;
      dispatch(setUserId(response.id));
      dispatch(setUsername(username));
      dispatch(setInitialEnter(false));
      dispatch(setHapticEnabled(is_haptic_feedback_enabled));
      dispatch(setIsGame(true));
      i18n.changeLanguage(language_code.toString());
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export { registerUser, getUser, fetchUserData };
