import React from "react";
import styles from "./styles.module.scss";
import { Caption } from "@telegram-apps/telegram-ui";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import cx from "classnames";

const ValidatedTask = ({
  t,
  accountName,
  handleSetAccountName,
  handleSubmitTask,
  task,
  isButtonDisabled,
}) => {
  return (
    <div className={styles.taskDetails}>
      {task.status === "started" && (
        <>
          <Caption className={styles.label} level="1" weight="1">
            {t("YOUR SOCIAL ACCOUNT HANDLE")}
          </Caption>
          <input
            className={styles.input}
            id="accountName"
            name="accountName"
            type="text"
            required
            minLength="3"
            placeholder={t("Please enter your social account handle")}
            value={accountName}
            onChange={handleSetAccountName}
          />
        </>
      )}
      <TelegramButton
        disabled={task.status === "started" && isButtonDisabled}
        className={cx(styles.telegramButton, {
          [styles.disabledButton]:
            task.status === "started" && isButtonDisabled,
        })}
        onClick={handleSubmitTask}
      >
        {task.status === "started" ? t("Claim") : task.button_text}
      </TelegramButton>
    </div>
  );
};

export default ValidatedTask;
