import cx from "classnames";
import styles from "./styles.module.scss";
import React from "react";

const NavBarItem = ({ mainSrc, isActive, onClick, disabled }) => {
  return (
    <div
      onClick={!disabled ? onClick : null}
      className={cx({
        [styles.navbarItem]: true,
        [styles.active]: isActive,
        [styles.menuItem]: !disabled,
        [styles.disabled]: disabled,
      })}
    >
      <img
        alt={"navItem"}
        src={mainSrc}
        width={"50px"}
        height={"50px"}
        className={styles.img}
      />
    </div>
  );
};

export default NavBarItem;
