import { axiosInstance } from "./axiosService";

const getUsersRating = () => {
  return axiosInstance.get("/users/rating");
};

const getUserRating = () => {
  return axiosInstance.get("/me/rating");
};

const getReferralAmount = () => {
  return axiosInstance.get("/me/referrals_money");
};

export { getUsersRating, getUserRating, getReferralAmount };
