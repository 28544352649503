import axios from "axios";
import humps from "humps";
import { store } from "../store/store";
import { setIsGame } from "../store/slices/telegramSlice";
import { apiUrl } from "../utils/globals";
import { toast } from "react-toastify";

export const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${window?.Telegram?.WebApp?.initData || ""}`,
  },
});

export const axiosListInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${window?.Telegram?.WebApp?.initData || ""}`,
  },
});

axiosInstance.interceptors.request.use((config) => {
  if (config.data) {
    config.data = humps.decamelizeKeys(config.data);
  }
  if (config.params) {
    config.params = humps.decamelizeKeys(config.params);
  }
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  return response.data;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(setIsGame(false));
    }
    if (error.response && error.response.status >= 500) {
      toast.error("Something went wrong, please reload page", {
        autoClose: 1000,
      });
    }
    return Promise.reject(error);
  },
);

axiosListInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(setIsGame(false));
    }
    if (error.response && error.response.status >= 500) {
      toast.error("Something went wrong, please reload page", {
        autoClose: 1000,
      });
    }
    return Promise.reject(error);
  },
);
