import React from "react";
import styles from "./styles.module.scss";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Image, Text } from "@telegram-apps/telegram-ui";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const HeroSkills = ({
  handlePrevPage,
  displayedSkills,
  activeSkill,
  handleSkillClick,
  handleNextPage,
  isDarkScheme,
}) => {
  const { t } = useTranslation();

  const getNonZeroAttributes = (skill) => {
    const attributes = {
      agility: skill.agility_base_effect,
      charisma: skill.charisma_base_effect,
      endurance: skill.endurance_base_effect,
      intelligence: skill.intelligence_base_effect,
      luck: skill.luck_base_effect,
      strength: skill.strength_base_effect,
    };

    return Object.entries(attributes)
      .filter(([key, value]) => parseFloat(value) > 0)
      .map(([key, value]) => [t(key), value]);
  };

  return (
    <>
      <div className={styles.skillsWrapper}>
        <button className={styles.arrow} onClick={handlePrevPage}>
          <FaChevronLeft className={styles.arrowIcon} />
        </button>
        <div className={styles.skillsContainer}>
          {displayedSkills.map((skill, index) => (
            <div
              key={skill.id}
              className={`${styles.skillWrapper} ${
                activeSkill === skill &&
                (isDarkScheme
                  ? styles.activeSkill
                  : styles.activeSkillDarkScheme)
              }`}
              onClick={() => handleSkillClick(skill)}
            >
              <Image
                className={cx(styles.skillIcon, {
                  [styles.disabledSkill]: !skill.active,
                })}
                key={index}
                size={48}
                src={skill.image_url}
              />
            </div>
          ))}
        </div>
        <button className={styles.arrow} onClick={handleNextPage}>
          <FaChevronRight className={styles.arrowIcon} />
        </button>
      </div>
      {activeSkill && (
        <div className={cx(styles.activeSkillWrapper, styles.fadeIn)}>
          <Text className={styles.skillName}>{activeSkill.name}</Text>
          <Text>
            {t("Required level")}:&nbsp;
            <Text className={styles.requiredLevel}>
              {activeSkill.required_level}
            </Text>
          </Text>
          <Text>{activeSkill.description}</Text>
          <Text
            className={styles.attributesTitle}
          >{`${t("Attributes bonus")}:`}</Text>
          {getNonZeroAttributes(activeSkill).map(([translatedKey, value]) => (
            <Text key={translatedKey}>
              {`${translatedKey}:`}
              &nbsp;
              <span className={styles.attributeBonus}>+&nbsp;{value}%</span>
            </Text>
          ))}
        </div>
      )}
    </>
  );
};

export default HeroSkills;
