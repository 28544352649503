import React from "react";
import styles from "./styles.module.scss";
import HeroCard from "../../components/ForHeroes/HeroCard/HeroCard";
import cx from "classnames";
import { useHeroHook } from "../../hooks/Pages/useHeroHook";
import CoinLoader from "../../components/CoinLoader/CoinLoader";
import AttributesRow from "../../components/ForHeroes/AttributesRow/AttributesRow";
import HeroCardTopRow from "../../components/ForHeroes/HeroCardTopRow/HeroCardTopRow";
import HeroCardLevel from "../../components/ForHeroes/HeroCardLevel/HeroCardLevel";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import { Text } from "@telegram-apps/telegram-ui";
import { Icon24ChevronLeft } from "@telegram-apps/telegram-ui/dist/icons/24/chevron_left";
import { Icon24ChevronRight } from "@telegram-apps/telegram-ui/dist/icons/24/chevron_right";

const Hero = ({ hero, setIsLoaderEnd }) => {
  const {
    heroData,
    handleClick,
    allImagesLoaded,
    handleOpenDetailPage,
    allLoaded,
    isImageLoaded,
    handleImageLoad,
    handleImageError,
    isLoading,
    swipeHandlers,
    isFromRoulette,
    goToNextHero,
    goToPrevHero,
    prevHeroId,
    nextHeroId,
  } = useHeroHook(setIsLoaderEnd, hero);

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <LoaderComponent />
      </div>
    );
  }

  if (!heroData) {
    return null;
  }

  return (
    <>
      {!allImagesLoaded && (
        <div className={styles.notLoaded}>
          <CoinLoader />
        </div>
      )}
      <div className={cx(styles.hero, styles.fadeIn)} {...swipeHandlers}>
        <HeroCardTopRow
          character={heroData}
          allImagesLoaded={allImagesLoaded}
        />
        {isFromRoulette ? (
          <Text className={styles.heroName}>{heroData.hero_name}</Text>
        ) : (
          <HeroCardLevel
            character={heroData}
            allImagesLoaded={allImagesLoaded}
          />
        )}
        <HeroCard
          handleOpenDetailPage={handleOpenDetailPage}
          character={heroData}
          onClick={handleClick}
          allLoaded={allLoaded}
          isImageLoaded={isImageLoaded}
          handleImageLoad={handleImageLoad}
          handleImageError={handleImageError}
        />
        <AttributesRow character={heroData} allImagesLoaded={allImagesLoaded} />
        {!isFromRoulette && (
          <>
            <button
              disabled={!prevHeroId}
              onClick={goToPrevHero}
              className={cx(styles.leftButton, {
                [styles.disabledButton]: !prevHeroId,
              })}
            >
              <Icon24ChevronLeft />
            </button>
            <button
              disabled={!nextHeroId}
              onClick={goToNextHero}
              className={cx(styles.rightButton, {
                [styles.disabledButton]: !nextHeroId,
              })}
            >
              <Icon24ChevronRight />
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default Hero;
