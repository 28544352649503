import React from "react";
import styles from "./styles.module.scss";
import { Avatar, Image, Snackbar } from "@telegram-apps/telegram-ui";
import { PreloadImages } from "../../../utils/globals";
import { useHeaderHook } from "../../../hooks/Layout/useHeaderHook";
import { MdMusicNote, MdMusicOff } from "react-icons/md";
import cx from "classnames";

const Header = () => {
  const {
    username,
    handleGoToProfile,
    handleGoToReferral,
    colorScheme,
    snackbarOpen,
    handleToggleMusic,
    handleCloseSnackbar,
    getDescriptionMessage,
    isThirdAttempt,
    isPlaying,
  } = useHeaderHook();

  return (
    <>
      <div className={styles.headerWrapper} id={"headerId"}>
        <div className={styles.username} onClick={handleGoToProfile}>
          <Avatar
            className={styles.avatar}
            size={20}
            src={
              colorScheme !== "dark"
                ? PreloadImages.PROFILE_DARK
                : PreloadImages.PROFILE_LIGHT
            }
          />
          <span>{username}</span>
        </div>
        <div className={styles.rightSide}>
          <Image
            className={styles.music}
            size={30}
            onClick={handleToggleMusic}
            src={
              isPlaying
                ? PreloadImages.MUSIC_ICON_ON
                : PreloadImages.MUSIC_ICON_OFF
            }
          />
          <Image
            className={styles.image}
            onClick={handleGoToReferral}
            size={30}
            src={PreloadImages.REFERRAL_ICON}
          />
        </div>
      </div>
      {snackbarOpen && (
        <Snackbar
          className={cx({ [styles.snackbar]: !isThirdAttempt })}
          before={isPlaying ? <MdMusicNote /> : <MdMusicOff />}
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          description={getDescriptionMessage()}
        >
          Path Of Discovery
        </Snackbar>
      )}
    </>
  );
};

export default Header;
