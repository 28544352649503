import { axiosInstance } from "./axiosService";

const getUserWallets = () => {
  return axiosInstance.get("/me/wallets");
};

const updateUserData = (userData) => {
  return axiosInstance.put("/me", userData);
};

export { getUserWallets, updateUserData };
